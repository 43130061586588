/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import MultiSelectCard from '../../Common/Cards/MultiSelectCard';

function WorkflowSelectWrapper(props) {
  const workflowList = useSelector((state) => state.user.workflowIdListWithDbData);
  if (workflowList && workflowList.length) {
    const options = workflowList.map(({ id, name }) => ({
      id,
      value: id,
      label: name || _.startCase(id),
    }));
    return (
      <MultiSelectCard
        {...props}
        options={options}
        title="This filter shows the workflow used for an application"
        placeholder="Select workflow Name"
      />
    );
  }
}

export default WorkflowSelectWrapper;
